import React, { useState, useEffect } from 'react'
import DashboardContainer, {
    DashboardInsideContainer,
} from "components/DashboardContainer";
import styled from "styled-components";
import { EventLoadingPreview } from "components/EventPreview";
// import firebase from "firebase/app";

const HackathonGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    margin-bottom: 200px;
`;

const HackathonFlexParent = styled.a`
    display: flex;
    align-items: center;
    @media only screen and (max-width: 600px) {
        align-items: flex-start;
    }
`;

const ProjectLogo = styled.img`
    width: calc(50px + 4vw);
    border-radius: calc(10px + 1vw);
`

const ProjectContent = styled.div`
    display: flex;
    width: 90%;
    margin-left: calc(12px + 1vw);
    flex-direction: column;
    align-items: flex-start;
`;

const ProjectName = styled.p`
    font-size: calc(16px + 0.4vw);
    font-weight: 600;
    color: #2C2929;
    margin-bottom: calc(2px + 0.1vw);
`

const TeamName = styled.p`
    font-size: calc(14px + 0.25vw);
    font-weight: 500;
    color: #2C2929;
    margin-bottom: calc(2px + 0.1vw);
`;

const ProjectBottomBorder = styled.div`
    height: 1px;
    width: 100%;
    background: #E1DDDD;
`;

const ProjectDescription = styled.p`
    font-size: calc(12px + 0.25vw);
    font-weight: 400;
    color: #677E9A;
`;

// const ProjectTeamFlex = styled.div`
//     display: flex;
//     width: 100%;
//     justify-content: space-between;
//     @media only screen and (max-width: 600px) {
//         flex-direction: column;
//     }
// `
// const TeamFlex = styled.div`
//     display: flex;
//     flex-direction: column;
// `;

// const TeamPhotosFlex = styled.div`
//     display: flex;
//     justify-content: flex-end;
//     @media only screen and (max-width: 600px) {
//         justify-content: flex-start;
//     }
// `;

// const TeamMemberPhoto = styled.img`
//     width: calc(10px + 1vw);
//     height: calc(10px + 1vw);
//     border-radius: 10px;
// `;


const HackathonBoard = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    // const [teamPhotos, setTeamPhotos] = useState([])

    useEffect(() => {
        fetch("https://api.airtable.com/v0/appMfz65jUsZMH1Ed/Hackathon?maxRecords=100&view=Grid+view", {
            "headers": {
                "authorization": "Bearer keyvXkgTDPamORxCA",
            },
            "method": "GET",
            "mode": "cors"
        })
        .then(res => res.json())
        .then(json => {
            setData(json.records.map((record) => record.fields))
            // const localData = json.records.map((record) => record.fields)
            // const localTeamMembers = []
            // localData.forEach((element, index) => {
            //     localTeamMembers.push({
            //         teamIndex: index,
            //         members: element["Hackathon Team Members"]
            //     })
            // })
            // const localTeamPhotos = []
            // localTeamMembers.forEach((team) => {
            //     const photos = []
            //     if (team.members) {
            //         team.members.forEach((member) => {
            //             firebase
            //             .database()
            //             // .ref('users/' + member) Please uncomment this in production
            //             .ref('users/RekM9MTsD4PLcEUDsjxwa0UViaT2') // Please remove later
            //             .get()
            //             .then((snapshot) => {
            //                 if (snapshot.exists()) {
            //                     const memberData = snapshot.val()
            //                     photos.push(memberData.imageURL ? memberData.imageURL : memberData.thumbnailURL)
            //                 }
            //             })
            //             .catch((error) => {
            //                 setLoading(false);
            //                 console.error(error);
            //             })
            //         })
            //     }
            //     localTeamPhotos.push({
            //         teamIndex: team.teamIndex,
            //         pictures: photos
            //     })
            // })
            // setTeamPhotos(localTeamPhotos)
            setLoading(false)
        })
        .catch((err) => {
            console.error(err)
            setLoading(false)
        })
    }, [])

    return (
        <DashboardContainer>
            <DashboardInsideContainer className="mt-5">
            <div className="d-flex align-items-center justify-content-between mb-5">
                <h1 className="text-xxxl text-weight-600 mb-4">Hackathon</h1>
                <a href="http://bit.ly/wwdc22hackathonvoting" className="btn btn-primary px-4 py-2 text-weight-500" target="_blank" rel="noopener noreferrer">VOTE</a>
            </div>
                <HackathonGrid>

                {loading &&
                    [1, 2, 3, 4, 5, 6].map((index) => (
                    <EventLoadingPreview key={index} />
                    ))
                }

                {!loading &&    
                    data.map((app, index) => (
                        <>
                        <HackathonFlexParent key={index} href={(`/hackathon/${app.team_name}`.toLowerCase()).replace(' ', '-')}>
                            <ProjectLogo alt="App Logo" src={app.project_logo_url} />
                            <ProjectContent>
                                <ProjectName>{app.project_name}</ProjectName>
                                {/* <ProjectTeamFlex> */}
                                    <TeamName>{app.team_name}</TeamName>
                                    <ProjectDescription>{app.description}</ProjectDescription>
                                    {/* <TeamFlex>
                                        <TeamPhotosFlex>
                                            {teamPhotos[index].pictures.map((picture) => (
                                                <TeamMemberPhoto src={picture} alt="Member"></TeamMemberPhoto>
                                            ))}
                                        </TeamPhotosFlex>
                                    </TeamFlex> */}
                                {/* </ProjectTeamFlex> */}
                            </ProjectContent>
                        </HackathonFlexParent>
                        <ProjectBottomBorder></ProjectBottomBorder>
                        </>
                    ))
                }

                </HackathonGrid>
            </DashboardInsideContainer>
        </DashboardContainer>
    )
}

export default HackathonBoard
