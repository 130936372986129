import React from "react";
import {
  SplashScreenContainer,
  SplashHeader,
  SplashHeading,
  Sponsors,
  LastYear,
  SplashButton,
} from "./styles";
// import { SplashScreenContainer, SplashHeader, SplashHeading, SponsorHeading, SponsorLogo, SupportColumn, Sponsors, LastYear, SplashButton, CommunityHeroes, Attendees, Team, EventVolunteers } from './styles';
import { Link } from "react-router-dom";
import SponsorFetch from "components/SponsorFetch";
import logo from "./../logo.png";
// import support from "./icons/support.svg";
// import volunteer from "./icons/volunteer.svg";
// import sponsor from "./icons/sponsor.svg";
import map from "../components/images/wwdccommunity.svg";
import keynote from "./icons/keynote.svg";
import openmic from "./icons/openmic.svg";
import group from "./icons/group.svg";
import mentorship from "./icons/mentorship.svg";
import hackathon from "./icons/hackathon.svg";
import { AccountContext } from "Account/store";
import SplashScreenCommunityPartners from "components/SplashScreenCommunityPartners";

class SplashScreen extends React.Component {
  static contextType = AccountContext;

  render() {
    var [state] = this.context;

    return (
      <SplashScreenContainer>
        <SplashHeader>
          <div className="container cta-links">
            {!state.user && (
              <p>
                <br></br>
                <Link to="/events">Events</Link> | {" "}
                <Link to="/login">Login</Link>
              </p>
            )}
          </div>
          <div className="container">
            <h1>WWDCCommunity Week 2022</h1>
            <object className="" data={map} type="image/svg+xml"></object>
            {/* <img src={logo} width="100%" className="App-logo" alt="logo" /> */}
            <h2>June 5-12, 2022</h2>

            <Link to={state.user ? "/dashboard" : "/join"}>
              <SplashButton className="btn btn-primary">
                {state.user ? "Enter dashboard" : "Register"}
              </SplashButton>
            </Link>
          </div>
        </SplashHeader>
        <div className="container">
          <div className="row no-gutters">
            <div>
              <h4>
                Connect, share, and collaborate with others during WWDC week
              </h4>
              <p>
                It's time to meetup! Catch up with your fellow developers in-person
                or online. Let's explore and
                discuss together the new exciting things from{" "}
                <a
                  className="red-link"
                  href="https://developer.apple.com/wwdc22/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apple’s WWDC 2022
                </a>{" "}
                week long event. 
                Wherever you are in the world, let connect and have an awesome time this WWDC.
                <br />
                <br />
                Check out what we did in the past year.
                <br />
                <LastYear href="https://wwdcwatch.party" target="_blank">
                  WWDC Watch Party
                </LastYear>
                <br />
                <LastYear href="https://2021.wwdc.community" target="_blank">
                  WWDC21 Community Week
                </LastYear>
              </p>
            </div>
          </div>

          <div className="row text-center">
            <SplashHeading>Events and Activities</SplashHeading>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img src={keynote} alt="A TV with a play button, keynote icon" />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Keynote Watch Party</h4>
              <p>
                Communities and friends have been setting up in-person WWDC Keynote Watch Parties.
                Find one in your area or join us online to watch together as a community.
              </p>
              <p>JUNE 06, 2022 PT</p>
            </div>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img src={openmic} alt="A microphone icon" />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Daily Recap</h4>
              <p>
                Once a day, we'll have our daily recap for WWDC. Listen to fellow developers
                as they share their experience and insights during that day.
              </p>
              <p>JUNE 06-12, 2022 PT</p>
            </div>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img
                src={group}
                alt="Four people surrounding a speech bubble, icon"
              />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Get Togethers</h4>
              <p>
                From Breakfast to Happy Hours, connect with your fellow developers in-person or online.
                Get Together Online Meetups will be happening too! See you there!
              </p>
              <p>JUNE 06-12, 2022 PT</p>
            </div>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img
                src={mentorship}
                alt="A hand holding a lightbulb emitting beam of light, icon"
              />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Community Hackathon</h4>
              <p>
                A 24 hour hackathon on the new technologies announced in WWDC22.
                There should at least be 2 members in your team and the winner
                will be voted by the community. Prizes await for this year's top three teams.
              </p>
              <p>JUNE 11, 2022 PT</p>
            </div>
          </div>
          <div className="row event">
            <div className="col-lg-1 col-md-2 col-sm-3">
              <img
                src={hackathon}
                alt="A laptop displaying binary digits next to a stopwatch, icon"
              />
            </div>
            <div className="col-lg-11 col-md-10 col-sm-9">
              <h4>Community Mural</h4>
              <p>
                This year we are starting a tradition where everyone can add their special
                moment on our Community Mural, from photos captured in events or moments experience at WWDC
                to links to your projects, writeups, youtube videos and more. Share this memory with the community.
              </p>
              <p>JUNE 5-12, 2022 PT</p>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <Link to="/events">
                <SplashButton className="btn btn-primary pink">
                  Join us!
                </SplashButton>
              </Link>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="text-center">
              <SplashHeading>Community Partners</SplashHeading>
              <div className="community-heroes mt-4">
                <SplashScreenCommunityPartners />
              </div> 
              <a href="/community-partners" className="btn btn-primary pink text-xl mt-5 text-weight-500 px-5 py-2">
                Know more
              </a>
            </div>
          </div>

          <div className="row text-center">
            <SplashHeading>Venue Partners</SplashHeading>
          </div>
          <Sponsors>
            <p className="text-center">
              Thank you for helping out host our community events.
            </p>
            <SponsorFetch />
          </Sponsors>

          {/*<!--div className="row text-center">
                        <SplashHeading>Community Heroes</SplashHeading>
                    </div>
                    <CommunityHeroes>
                        <p>Thank you to all these generous people who helped support other attendees.</p>
                    </CommunityHeroes>
                    <div className="row text-center">
                        <SplashHeading>Attendees</SplashHeading>
                    </div>
                    <Attendees>
                        <button>See all attendees</button>
                    </Attendees>
                    <div className="row text-center">
                        <SplashHeading>Team</SplashHeading>
                        </div>
                    <Team>
                        <p>Thank you to all these people who volunteered their time, effort, and skills in making this event happen.</p>
                    </Team>
                    <div className="row text-center">
                        <SplashHeading>Event Volunteers</SplashHeading>
                    </div>
                    <EventVolunteers>
                        <button>See profiles and roles</button>
                        <p>Thank you to all these generous people who helped support other attendees.</p>
                    </EventVolunteers>*/}
          <br />
          <br />
          <br />
        </div>
        <footer>
          <div className="container">
            <div className="d-flex flex-column flex-md-row justify-content-between text-center text-md-left">
              Copyright 2022
              <div className="footer-links d-flex flex-column flex-md-row">
                <div>
                  <a
                    href="https://docs.google.com/document/d/10M9OqeiFduAXRP_nl0EGPPjR-iAn8DQef_kEXWZQNj8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms &amp; Conditions
                  </a>
                </div>
                <div>
                  <a
                    href="https://docs.google.com/document/d/1dLCUHP7p_Gp1tlUP7aBKcWw3ZbaVy1GmkCuG2xE8dIU"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
                <div>
                  <a
                    href="https://docs.google.com/document/d/1ljIzgemDwZjRjfA71uJAZPcmClt0F4vYk2ExT25OvCw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Code of Conduct
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="container disclaimers">
          <p>
            DISCLAIMER
            <br />
            <br />
            WWDC Community Week (wwdc.community) is a community run event made
            with ❤️ by volunteers around the world. It is not affiliated with or
            endorsed by Apple, Inc. or Apple's Worldwide Developer Conference.
            The Apple Logo are trademarks of Apple Inc., registered in the U.S.
            and other countries.
          </p>
        </div>
      </SplashScreenContainer>
    );
  }
}

export default SplashScreen;
