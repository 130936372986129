import { useState, useEffect } from "react";
import firebase from "firebase/app";
import DashboardContainer, {
  DashboardInsideContainer,
} from "components/DashboardContainer";
import NewUsersMessage from "components/NewUsersMessage";
import EventHostInvitation from "components/EventHostInvitation";
import styled from "styled-components";
import { EventLoadingPreview, EventPreview } from "components/EventPreview";
import dayjs from "dayjs";

export const EventsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 40px;
  width: 70%;
  max-width: 700px;
  padding-bottom: 50px;

  @media only screen and (max-width: 1270px) {
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  @media only screen and (min-width: 1600px) {
    width: 80%;
    max-width: 1200px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const EventsScreen = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [ongoingEventKeys, setOngoingEventKeys] = useState([]);
  const [doneEventKeys, setDoneEventKeys] = useState([]);

  useEffect(() => {
    setLoading(true);

    firebase
      .database()
      .ref("events")
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const events = snapshot.val();
          let sortedKeys = Object.keys(events).sort(function (a, b) {
            return (
              new Date(events[a].start_date) - new Date(events[b].start_date)
            );
          });

          let doneEventKeys = sortedKeys.filter((eventKey) => {
            const event = events[eventKey];
            const isEventDone = dayjs(event.end_date).isBefore(dayjs());
            return isEventDone;
          });

          let ongoingEventKeys = sortedKeys.filter(
            (eventKey) => !doneEventKeys.includes(eventKey)
          );

          setDoneEventKeys(doneEventKeys);
          setOngoingEventKeys(ongoingEventKeys);
          setEvents(events);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, []);

  return (
    <DashboardContainer>
      <EventHostInvitation />
      <DashboardInsideContainer className="mt-5">
        <h1 className="text-xxxl text-weight-600 mb-4">Upcoming Events</h1>
        <EventsGrid>
          {loading &&
            [1, 2, 3, 4, 5, 6].map((index) => (
              <EventLoadingPreview key={index} />
            ))}

          {!loading &&
            ongoingEventKeys.map((key, index) => (
              <EventPreview
                slug={key}
                event={events[key]}
                index={index}
                key={index}
              />
            ))}
        </EventsGrid>
        <h1 className="text-xxxl text-weight-600 mb-4">Past Events</h1>
        <EventsGrid>
          {!loading &&
            doneEventKeys.map((key, index) => (
              <EventPreview
                slug={key}
                event={events[key]}
                index={index}
                key={index}
              />
            ))}
        </EventsGrid>
      </DashboardInsideContainer>
    </DashboardContainer>
  );
};

export default EventsScreen;
