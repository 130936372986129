import styled from "styled-components";
import HouseIcon from "components/icons/HouseIcon";
import { sponsors } from "components/constants";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AccountContext } from "Account/store";
import firebase from "firebase/app";
import ProfileImageContainer from "components/ProfileImageContainer";

const Sidebar = styled.div`
  @media (min-width: 576px) and (max-width: 992px) {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SidebarLinkContainer = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
`;

const SidebarLink = styled.a.attrs((props) => ({
  active: props.active || false,
}))`
  width: 100%;
  padding: 5px 0;
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => (props.active ? "#fb0c62" : "white")};
  display: block;

  :hover {
    text-decoration: none;
    color: #fb0c62;
  }
`;

const SidebarTitle = styled.div`
  font-size: 14px;
  color: #c7bbe7;
  text-transform: uppercase;
`;

const SidebarLogo = styled.img`
  width: 60%;
`;

const DashboardSidebar = () => {
  const [state, dispatch] = useContext(AccountContext);
  let history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const logout = () => {
    firebase.auth().signOut();
    dispatch({ type: "LOGOUT" });
    history.push("/");
  };

  const sidebarLinks = {
    activities: [
      { title: "Join Discord - Official Venue", link: "https://discord.gg/3P94atxcV5"},
      { title: "Mural", link: "/mural" },
      { title: "Events", link: "/events" },
      { title: "Hackathon", link: "/hackathon" },
      //{ title: "Mentoring Labs", link: "/mentoring-labs" },
      //{ title: "Attendees", link: "/attendees" },
      //{ title: "Volunteers", link: "/volunteers" },
      /*
      {
        title: "Community Heroes ",
        link: "/community-heroes",
        subtitle: "(Attendee Supporters)",
      },
      */
      
      {
        title: "Community Partners",
        link: "/community-partners",
      },
      
    ],
    ...(state.user && {
      you: [{ title: "Edit Profile", link: "/profile" }],
    }),
    // participants: [
    //   { title: "Attendees", link: "/attendees" },
    //   { title: "Volunteers", link: "/volunteers" },
    //   {
    //     title: "Community Partners",
    //     link: "/community-partners",
    //   },
    // ],

    /*
    others: [
      { title: "Job Board", link: "/job-board" },
      { title: "App Board", link: "/app-board" },
      {
        title: "Chip in",
        link: "https://ko-fi.com/s/fe30d889f8 ",
      },
    ],
    */
  };
  const sidebarLinksMap = Object.entries(sidebarLinks);

  const SidebarLinkComponent = ({ href, title }) => (
    <SidebarLink href={href} active={href === pathname}>
      {title}
    </SidebarLink>
  );

  return (
    <Sidebar className="d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-between w-100">
        <div className="text-xl text-weight-600 mr-3">
          WWDC22
          <br />
          Community Week
        </div>
        <a className="mt-2 d-block" href={state.user ? "/dashboard" : "/"}>
          <HouseIcon />
        </a>
      </div>

      {state.user && (
        <a href={`/attendees/${state.user.uid}`}>
          <div className="d-flex align-items-center w-100 mt-5">
            {state.profile.imageURL && (
              <ProfileImageContainer
                width="50px"
                height="50px"
                src={state.profile.imageURL}
                alt="profile"
                loading="lazy"
                className="mr-3"
              />
            )}
            <div className="text-weight-500">
              <div className="text-xl text-white">
                {state.profile.firstName}
              </div>

              {/* <div className="text-md"> */}
              {/*   {state.user.isSupporter && ( */}
              {/*     <span className="text-success"> */}
              {/*       Supporter + Ticket Holder */}
              {/*     </span> */}
              {/*   )} */}
              {/*   {state.user.isVolunteer && ( */}
              {/*     <span className="text-success"> */}
              {/*       Volunteer + Ticket Holder */}
              {/*     </span> */}
              {/*   )} */}
              {/*   {state.user.wasGrantedTicket && ( */}
              {/*     <span className="text-success">Ticket Holder</span> */}
              {/*   )} */}
              {/*   {!state.user.isSupporter && */}
              {/*     !state.user.isVolunteer && */}
              {/*     !state.user.wasGrantedTicket && ( */}
              {/*       <span className="text-white">Free Ticket Tier</span> */}
              {/*     )} */}
              {/* </div> */}

            </div>
          </div>
        </a>
      )}

      {!state.user && (
        <div className="text-xl mt-5">
          <div className="text-weight-500">Join us and</div>
          <a href="/join" className="text-weight-600 text-pink">
            Register now
          </a>
        </div>
      )}


      {/* <SidebarLinkContainer> */}
      {/*   <SidebarTitle>Platinum Sponsor</SidebarTitle> */}
      {/*   {sponsors.platinum.map((sponsor) => ( */}
      {/*     <a href="/goodnotes" key={sponsor.name}> */}
      {/*       <SidebarLogo src={sponsor.logo} alt={sponsor.name} /> */}
      {/*     </a> */}
      {/*   ))} */}
      {/*   <SidebarLinkComponent href="/sponsors" title="All Sponsors" /> */}
      {/* </SidebarLinkContainer> */}

      {sidebarLinksMap.map((sidebarLink) => (
        <SidebarLinkContainer key={sidebarLink[0]}>
          <SidebarTitle>{sidebarLink[0]}</SidebarTitle>
          {sidebarLink[1].map((link) => (
            <div key={link.title}>
              <SidebarLinkComponent href={link.link} title={link.title} />
              <div className="text-gray text-sm">{link.subtitle}</div>
            </div>
          ))}
        </SidebarLinkContainer>
      ))}

      {state.user && (
        <SidebarLink href="" onClick={logout} className="mt-3">
          Sign out
        </SidebarLink>
      )}
      {!state.user && (
        <SidebarLink href="/join" className="mt-3">
          Login / Register
        </SidebarLink>
      )}
    </Sidebar>
  );
};

export default DashboardSidebar;
