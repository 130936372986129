import React from "react";
import "./App.css";
import LoginScreen from "./Account/LoginScreen/LoginScreen";
import CreateAccountScreen from "./Account/CreateAccountScreen/CreateAccountScreen";
import SplashScreen from "./SplashScreen/SplashScreen";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ForgotPasswordScreen from "Account/ForgotPasswordScreen";
import AccountStore, { AccountContext } from "./Account/store";
import CreateProfileScreen from "Account/CreateProfileScreen";
import ProfileScreen from "Dashboard/ProfileScreen";

import DashboardScreen from "Dashboard/DashboardScreen";
import EventsScreen from "Dashboard/EventsScreen";
import EventScreen from "Dashboard/EventScreen";
import AttendeeScreen from "Dashboard/AttendeeScreen";
import AttendeesScreen from "Dashboard/AttendeesScreen";
import CommunityHeroes from "Dashboard/CommunityHeroes";
import SponsorsScreen from "Dashboard/SponsorsScreen";
import MentoringLabsScreen from "Dashboard/MentoringLabsScreen";
import MentoringLabScreen from "Dashboard/MentoringLabScreen";
import JobBoard from "Dashboard/JobBoard";
import AppBoard from "Dashboard/AppBoard";
import GoodNotes from "Dashboard/GoodNotes";
import Volunteers from "Dashboard/Volunteers";
import HackathonBoard from "Dashboard/HackathonBoard"
import HackathonScreen from "Dashboard/HackathonScreen"
import Mural from "Dashboard/Mural"

import EventsDownloadScreen from "Dashboard/EventsDownloadScreen";

import firebase from "firebase/app";
import "firebase/database";
import "firebase/analytics";

import MainLoadingContainer from "components/MainLoadingContainer";
import CommunityPartners from "Dashboard/CommunityPartners";

class MainRouter extends React.Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  initializeFirebase() {
    // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
    if (process.env.NODE_ENV === "development") {
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional
      var firebaseConfig = {
        apiKey: "AIzaSyCZjjVEYtrfVY-5H0OwCJmU9irNYV1nhOM",
        authDomain: "wwdc-community-develop.firebaseapp.com",
        projectId: "wwdc-community-develop",
        storageBucket: "wwdc-community-develop.appspot.com",
        messagingSenderId: "390428972090",
        appId: "1:390428972090:web:5f63f6893994e992b11103",
        measurementId: "G-4LPG8HY2P5",
      };
    } else {
      firebaseConfig = {
        apiKey: "AIzaSyDw1I77_7_u1KMeuzrbIDr4LBmEL82cukI",
        authDomain: "wwdc-community-2022.firebaseapp.com",
        projectId: "wwdc-community-2022",
        storageBucket: "wwdc-community-2022.appspot.com",
        messagingSenderId: "570557683504",
        appId: "1:570557683504:web:9f86c6bce57795f13ddaf5",
        measurementId: "G-LWTL13RERJ",
      };
    }

    // Initialize Firebase
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
      firebase.analytics();
    }
  }

  getBadges() {
    const [state, dispatch] = this.context;

    firebase
      .database()
      .ref(`badges/${state.user.uid}`)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          dispatch({
            type: "UPDATE_USER_TYPE",
            payload: Object.entries(snapshot.val())
              .filter((item) => item[1])
              .map((item) => item[0]),
          });
        }
      });
  }

  checkIfAdmin() {
    const [state, dispatch] = this.context;

    firebase
      .database()
      .ref("administrators")
      .get()
      .then((snapshot) => {
        dispatch({
          type: "SET_ADMIN_PRIVILEGES",
          payload: snapshot.exists() && state.user.uid in snapshot.val(),
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateCurrentUser() {
    firebase.auth().onAuthStateChanged((user) => {
      const [state, dispatch] = this.context;
      if (user) {
        // User is already signed in.
        dispatch({ type: "LOGIN", payload: user });

        firebase
          .database()
          .ref("users/" + user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              dispatch({ type: "PROFILE_UPDATE", payload: snapshot.val() });
              this.getBadges();
              this.checkIfAdmin();
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
          });
      } else if (state.user) {
        dispatch({ type: "LOGOUT" });
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  componentDidMount() {
    this.initializeFirebase();
    this.updateCurrentUser();
  }

  logout() {
    firebase.auth().signOut();
  }

  render() {
    if (this.state.loading) {
      return <MainLoadingContainer />;
    }

    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/login">
              <LoginScreen />
            </Route>
            <Route path="/join">
              <CreateAccountScreen />
            </Route>
            <Route path="/forgot-password">
              <ForgotPasswordScreen />
            </Route>
            <Route path="/create-profile">
              <CreateProfileScreen />
            </Route>
            <Route path="/profile">
              <ProfileScreen />
            </Route>
            <Route exact path="/dashboard">
              <DashboardScreen />
            </Route>
            <Route exact path="/events">
              <EventsScreen />
            </Route>
            {/* <Route exact path="/community-heroes"> */}
            {/*   <CommunityHeroes /> */}
            {/* </Route> */}
            <Route exact path="/mural">
              <Mural />
            </Route>
            <Route exact path="/community-partners">
              <CommunityPartners />
            </Route>
            <Route exact path="/events/:id" children={<EventScreen />} />
            <Route exact path="/attendees">
              <AttendeesScreen />
            </Route>
            <Route exact path="/attendees/:id" children={<AttendeeScreen />} />
            {/* <Route exact path="/sponsors"> */}
            {/*   <SponsorsScreen /> */}
            {/* </Route> */}
            {/* <Route exact path="/goodnotes"> */}
            {/*   <GoodNotes /> */}
            {/* </Route> */}
            {/* <Route exact path="/job-board"> */}
            {/*   <JobBoard /> */}
            {/* </Route> */}
            {/* <Route exact path="/app-board"> */}
            {/*   <AppBoard /> */}
            {/* </Route> */}
            {/* <Route exact path="/mentoring-labs"> */}
            {/*   <MentoringLabsScreen /> */}
            {/* </Route> */}
            {/* <Route exact path="/mentoring-labs/:id"> */}
            {/*   <MentoringLabScreen /> */}
            {/* </Route> */}
            <Route exact path="/events-download">
              <EventsDownloadScreen />
            </Route>
            <Route exact path="/volunteers">
              <Volunteers />
            </Route>
            <Route exact path="/hackathon">
              <HackathonBoard />
            </Route>
            <Route exact path="/hackathon/:teamName">
              <HackathonScreen />
            </Route>
            <Route>
              <SplashScreen />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

function App() {
  return (
    <AccountStore>
      <MainRouter />
    </AccountStore>
  );
}

export default App;
