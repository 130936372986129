import React, { useState, useEffect } from 'react'
import DashboardContainer, { DashboardInsideContainer } from "components/DashboardContainer";
import styled from "styled-components";
import dayjs from "dayjs";
import globalConstants from 'globalConstants';

const CommunityPartnerContainer = styled.div`
    width: 100%;
    box-shadow: 0px 4px 17px rgba(103, 94, 94, 0.125);
    color: #2c2929;
    transition: box-shadow 0.8s ease;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    :hover {
        color: #2c2929;
        box-shadow: 0px 10px 20px rgba(103, 94, 94, 0.25);
    }

    display: grid;
    grid-template-columns: 14px 80px 1fr;
    grid-column-gap: 16px;

    img {
      margin-top:33px;
      height:80px;
      width:80px;
      object-fit: contain;
    }
`

const CommunityPartner = styled.div`
    padding: 43px 12px 40px 38px;

    a {
      color: #2C2929;
    }

    h1.events {
      font-size:18px;
      font-weight:500;
    }

    h1.uppercase {
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    p {
      font-size:14px;
      font-weight:500;
    }
`

const ColourBar = styled.div`
    height:100%;
`

const headingColors = [
  "#2BE3CD",
  "#FFEA2E",
  "#36B6FF",
  "#FF4768",
  "#FF8C38",
];

const CommunityPartners = () => {
  const [loading, setLoading] = useState(true)
  const [partners, setPartners] = useState([])
  const [events, setEvents] = useState({})
  const offset = dayjs().format("Z");

  useEffect(() => {
    fetch("https://api.airtable.com/v0/appMfz65jUsZMH1Ed/Community%20Partners?maxRecords=50&view=Grid+view", {
      "headers": {
        "authorization": "Bearer keyvXkgTDPamORxCA",
        "x-airtable-application-id": "appMfz65jUsZMH1Ed",
        "content-type": "application/json",
        "x-airtable-user-agent": "Airtable.js/undefined"
      },
      "method": "GET",
      "mode": "cors"
    })
    .then(res => res.json())
    .then(json => {
      setPartners(json.records.map((partner) => partner.fields))
      loadEvents()
    })
    .catch((err) => {
      console.error(err)
    })
  }, [])

  const loadEvents = () => {
    fetch("https://api.airtable.com/v0/appMfz65jUsZMH1Ed/CP%20Events?maxRecords=50&view=Grid+view", {
      "headers": {
        "authorization": "Bearer keyvXkgTDPamORxCA",
        "x-airtable-application-id": "appMfz65jUsZMH1Ed",
        "content-type": "application/json",
        "x-airtable-user-agent": "Airtable.js/undefined"
      },
      "method": "GET",
      "mode": "cors"
    })
    .then(res => {
      setLoading(false)
      return res.json()
    })
    .then(json => {
      setEvents(json.records.reduce((accumulator, currentValue) => {
        accumulator[currentValue.id] = currentValue.fields
        return accumulator
      }, {}))
    })
    .catch(error => {
      console.error(error)
    })

  }

  return(
    <>
      <DashboardContainer>
        <DashboardInsideContainer className="mt-5">
          <h1 className="text-xxxl text-weight-600 mb-4">Community Partners</h1>
          {!loading &&
            partners.map((partner, index) => (
              <CommunityPartnerContainer key={index} className="mb-4">
                <ColourBar style={{backgroundColor:headingColors[index % headingColors.length]}} />
                <img src={partner['logo_url']} alt={partner['community_name'] + ' Logo'} />
                <CommunityPartner>
                  <h1 key={index} className="text-xxl">{partner['community_name']}</h1>
                  <p>{partner['description']}</p>
                  <div>
                    { partner['CP Events'] !== undefined && partner['CP Events'].length > 0 &&
                      <>
                        <h1 className="text-cobble events uppercase">Events</h1>
                        { partner['CP Events'].flatMap((event, index) => {
                          if (!events[event]) {
                            return null;
                          }
                          
                          const eventDate = new Date(events[event]['schedule'])
                          const formattedDate = dayjs(
                            eventDate,
                            globalConstants.dateFormats.firebase
                          ).format(globalConstants.dateFormats.monthWithDay);
                          

                          return (<a key={event} href={events[event]['event_link']} target="_blank" rel="noreferrer">
                            <h1 className="events">{events[event]['event_name']}</h1>
                            <p>{formattedDate} (GMT{offset})</p>
                            <hr />
                          </a>)
                        })}
                      </>
                    } 
                  </div>
                </CommunityPartner>
              </CommunityPartnerContainer>
            ))
          }
        </DashboardInsideContainer>
      </DashboardContainer>
    </>
  )
}


export default CommunityPartners