const LocationIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="10 0 10 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip1)">
      <path 
        d="M11 0C8.54002 0 6.48828 1.9816 6.48828 4.51172C6.48828 5.47427 6.77752 6.33259 7.33263 7.13688L10.6547 12.3207C10.8159 12.5727 11.1844 12.5722 11.3453 12.3207L14.6818 7.11925C15.2249 6.35141 15.5117 5.4498 15.5117 4.51172C15.5117 2.02396 13.4878 0 11 0ZM11 6.5625C9.86925 6.5625 8.94922 5.64247 8.94922 4.51172C8.94922 3.38097 9.86925 2.46094 11 2.46094C12.1307 2.46094 13.0508 3.38097 13.0508 4.51172C13.0508 5.64247 12.1307 6.5625 11 6.5625Z" 
        fill="black"
      />
      <path d="M14.2064 9.42529L12.1411 12.6542C11.6065 13.4877 10.3905 13.485 9.85845 12.655L7.78979 9.42614C5.96968 9.84696 4.84766 10.6179 4.84766 11.5391C4.84766 13.1377 8.01756 14 11 14C13.9824 14 17.1523 13.1377 17.1523 11.5391C17.1523 10.6172 16.0288 9.84589 14.2064 9.42529Z" 
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip1">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LocationIcon;


