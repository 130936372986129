import styled from "styled-components";

const ProfileImageContainer = styled.img.attrs((props) => ({
  width: props.width || "50px",
  height: props.height || "50px",
}))`
  pointer-events: none;
  object-fit: contain;
  border-radius: 999px;
  width: ${(props) => props.width} !important;
  height: ${(props) => props.height} !important;
`;

export default ProfileImageContainer;
