import { DashboardInsideContainer } from "components/DashboardContainer";
import styled from "styled-components";
// import { useContext } from "react";
// import { AccountContext } from "Account/store";

const EventHostInvitationContainer = styled.div`
  background: #f3f3f3;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 0px;

  a {
    margin-top: 10px;
  }

`;

const EventHostInvitation = () => {

    return (
      <EventHostInvitationContainer>
        <DashboardInsideContainer>
          <div className="text-weight-600 mb-1">
            Welcome to WWDCCommunity Week 2022!
          </div>
          <p className="mb-0 mt-2">
            It's time to Meetup! You can now start RSVP'ing to events, make sure to sign up to RSVP.
            <br></br><br></br>
            Can't find an event in your area? Why not host one? It doesn't have to be a formal event or a big gathering. It can be lunch, dinner or a fun activity with developers in your area. Will try our best to connect you with them.
          </p>

          <a
            className="btn btn-primary px-4 py-2 text-weight-500"
            href="https://bit.ly/WWDCCommunityWeek2022-Hosts"
            target="_blank"
            rel="noopener noreferrer"
          >
            I want to host
          </a>
        </DashboardInsideContainer>
      </EventHostInvitationContainer>
    );
  }

export default EventHostInvitation;
