import { useContext } from "react";
import { AccountContext } from "Account/store";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import logo from "logo.png";

const AccountDiv = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  width: 474px;
  margin: 40px auto;
  text-align: center;
  color: black;

  h1 {
    font-size: 30px;
    margin-bottom: 31px;
  }

  form {
    padding: 0px 40px;
  }

  button {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 8px;
  }

  @media only screen and (max-width: 600px) {
    padding: 40px 30px;
    width: 90%;

    h1 {
      font-size: 24px;
    }

    form {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

const AccountHeader = styled.div`
  padding: 20px;
  margin: 20px auto;
  text-align: center;

  h1 {
    font-weight:800;
    font-size: 2.8rem;
    background: linear-gradient(to right, #e8ebfe 0%, #a0adfb 50%, #c0c9fe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  h2 {
    color: #fff;
    font-size: 2rem;
    font-weight:600;
  }

  @media only screen and (max-width: 600px) {
    padding: 0px 0px;
    width: 90%;

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.2rem;
    }

  }
`;

const AccountContainer = ({ children, className }) => {
  const [state] = useContext(AccountContext);

  if (state.profile !== null && window.location.pathname !== "/dashboard") {
    return <Redirect to="/dashboard" />;
  } else if (
    state.profile === null &&
    state.user !== null &&
    window.location.pathname !== "/create-profile"
  ) {
    return <Redirect to="/create-profile" />;
  }
  return (
    <>
      <AccountHeader className="App-header">
        <a href="/">
          <h1 className="event-name">WWDCCommunity Week 2022</h1>
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
        </a>
        <h2>Jun 5-12, 2022</h2>
      </AccountHeader>
      <AccountDiv className={className}>{children}</AccountDiv>
    </>
  );
};

export default AccountContainer;
