import React, { useState, useEffect } from "react";

const SplashScreenCommunityPartners = () => {
  const [communityPartners, setCommunityPartners] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      "https://api.airtable.com/v0/appMfz65jUsZMH1Ed/Community%20Partners?maxRecords=50&view=Grid+view",
      {
        headers: {
          authorization: "Bearer keyvXkgTDPamORxCA",
          "x-airtable-application-id": "appMfz65jUsZMH1Ed",
          "content-type": "application/json",
          "x-airtable-user-agent": "Airtable.js/undefined",
        },
        method: "GET",
        mode: "cors",
      }
    )
      .then((res) => res.json())
      .then((json) => {
        setCommunityPartners(json.records.map((partner) => partner.fields));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading &&
        communityPartners.map((communityPartner, index) => (
          <a href={communityPartner.website} target="_blank" rel="noopener noreferrer">
            <img
              src={communityPartner.logo_url}
              alt={`${communityPartner.community_name} logo`}
            />
          </a>
        ))}
    </>
  );
};

export default SplashScreenCommunityPartners;
