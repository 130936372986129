import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { Link } from "react-router-dom";
import { AccountContext } from "../store";
import styled from "styled-components";
import AccountContainer from "../../components/AccountContainer";

export const CreateAccountContainer = styled(AccountContainer)`
  .form-check {
    text-align: left;
    width: 85%;
    margin: 0 auto;
  }
  @media only screen and (max-width: 600px) {
    .CreateAccountScreen form {
      padding-left: 0px;
      padding-right: 0px;
    }

    .CreateAccountScreen button {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;

class CreateAccountScreen extends React.Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);
    this.createAccount = this.createAccount.bind(this);
    this.state = {
      message: null,
      agreeToTerms: false,
      email: "",
      password: "",
      loading: false,
    };
  }

  createAccount(event) {
    event.preventDefault();
    this.setState({ loading: true });

    firebase
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((error) => {
        // Display Error Message to User:
        this.setState({
          message: error.message,
          loading: false,
        });
      });
  }

  handleInputChange(name, value) {
    this.setState({ [name]: value });
  }

  render() {
    return (
      <CreateAccountContainer>
        <h1>Create Account</h1>
        <p>{this.state.message}</p>
        <form onSubmit={this.createAccount}>
          <input
            name="email"
            type="email"
            placeholder="Email Address"
            className="form-control mb-4"
            value={this.state.email}
            onChange={(event) =>
              this.handleInputChange("email", event.target.value)
            }
            autoFocus
          ></input>
          <input
            name="password"
            type="password"
            placeholder="Password"
            className="form-control mb-4"
            value={this.state.password}
            onChange={(event) =>
              this.handleInputChange("password", event.target.value)
            }
          ></input>
          <div className="form-check mb-4">
            <input
              className="form-check-input"
              type="checkbox"
              checked={this.state.agreeToTerms}
              onChange={() =>
                this.handleInputChange("agreeToTerms", !this.state.agreeToTerms)
              }
            />
            <label className="form-check-label text-lato">
              I agree to{" "}
              <a href="https://docs.google.com/document/d/10M9OqeiFduAXRP_nl0EGPPjR-iAn8DQef_kEXWZQNj8/edit?usp=sharing">
                Terms
              </a>
              ,{" "}
              <a href="https://docs.google.com/document/d/1dLCUHP7p_Gp1tlUP7aBKcWw3ZbaVy1GmkCuG2xE8dIU/edit?usp=sharing">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="https://docs.google.com/document/d/1ljIzgemDwZjRjfA71uJAZPcmClt0F4vYk2ExT25OvCw/edit?usp=sharing">
                Code of Conduct
              </a>
            </label>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              !this.state.agreeToTerms ||
              !this.state.email ||
              !this.state.password ||
              this.state.loading
            }
          >
            Create Account
          </button>
        </form>
        <p className="text-sm text-cobble text-weight-400 mb-5">
          Already have an account? <Link to="/login">Login</Link>
        </p>
        
        {/* <p className="text-sm text-cobble mb-0">Support the Event</p> */}
        {/* <a */}
        {/*   href="https://ko-fi.com/s/4d8fb2181a" */}
        {/*   target="_blank" */}
        {/*   rel="noopener noreferrer" */}
        {/*   className="text-sm" */}
        {/* > */}
        {/*   Get your ticket here */}
        {/* </a> */}
      </CreateAccountContainer>
    );
  }
}

export default CreateAccountScreen;
