import React, { useState, useEffect } from 'react'
import DashboardContainer, {
    DashboardInsideContainer,
  } from "components/DashboardContainer";
import styled from "styled-components";
import { EventLoadingPreview } from "components/EventPreview";
import IframeResizer from 'iframe-resizer-react';


const MuralDiv = styled.div`
  margin-top: 30px;
  margin-bottom: 100px;

  a {
    margin-top: 10px;
    margin-bottom: 20px;
  }

`

const MuralScreen = () => {

    return (
        <>
            <DashboardContainer>
                <DashboardInsideContainer className="mt-5">
                    <MuralDiv>
                        <h1 className="text-xxxl text-weight-600 mb-0">WWDCCommunity Mural</h1>
                        <a
                            href="https://app.mural.co/t/wwdccommunity8619/m/wwdccommunity8619/1653410509217/833feaa604a49004b3f904775fc3e9d69894a3ae"
                            className="btn btn-primary px-4 py-2 text-weight-500"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Share your WWDC Experience Here
                        </a>
                        <p>Note: Please do not delete submissions made by the community. Find a space and add yours. All submission should follow the  
                        {' '}
                        <a
                            className="text-pink text-weight-500"
                            href="https://docs.google.com/document/d/1ljIzgemDwZjRjfA71uJAZPcmClt0F4vYk2ExT25OvCw/edit"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                        Community Code of Conduct
                        </a>
                        . Just in case, you are using a mobile phone, adding to the Mural requires download of Mural App. Enjoy!</p>
                        
                        <IframeResizer
                          log
                          src="https://app.mural.co/embed/eb48aa7e-fb6d-4f04-8547-fd7b426d6a28"
                          style={{ width: '100%', height: '480px', minWidth: '100%'}}
                          frameBorder="0"
                        />

                    </MuralDiv>
                </DashboardInsideContainer>
            </DashboardContainer>
        </>
    )
}

export default MuralScreen
